@import 'reset-css';
@font-face {
  font-family: 'nunito';
  src: url('./fonts/Nunito-VariableFont_wght.ttf') format('truetype');
}
body {
  background-color: #F3F3F3;
}

a, a:hover, a:visited {
  text-decoration: none;
}

.App {
  text-align: center;
  width: 57em;
  max-width: 100%;
  margin: 0 auto;
}

header {
  margin: 40px 0 20px;
}

header h1 {
  font-family: 'nunito', arial, sans-serif;
  font-weight: 800;
  font-size: 48px;
  margin: 0 0 10px;
}

header p {
  font-family: 'nunito', arial, sans-serif;
  color: rgba(0, 0, 0, 0.4);
}

main {
  margin: 80px auto;
}

main h3 {
  font-family: 'nunito', arial, sans-serif;
  font-weight: 800;
  font-size: 32px;
  margin: 0 0 50px;
}

main p.tips {
  margin-top: 30px;
  font-family: 'nunito', arial, sans-serif;
  color: #ff0000;
}

.upload-label {
  display: inline-block;
  background-color: #ff0000;
  color: #fff;
  border-radius: 35px;
  cursor: pointer;
  text-align: center;
  margin-top: 50px;
  height: 50px;
  line-height: 50px;
  padding: 10px 30px;
  font-family: 'nunito', arial, sans-serif;
  font-weight: 800;
  font-size: 28px;
}

.preview-image {
  margin-top: 20px;
  max-width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'nunito', arial, sans-serif;
  font-weight: 500;
  font-size: 22px;
  z-index: 10;
}

.download-button {
  background-color: #ff0000;
  color: #fff;
  border-radius: 35px;
  text-decoration: none;
  margin-top: 10px;
  height: 50px;
  line-height: 50px;
  padding: 10px 30px;
  font-family: 'nunito', arial, sans-serif;
  font-weight: 600;
  font-size: 22px;
}

.upload-another-button {
  background-color: #ccc;
  color: #fff;
  border-radius: 35px;
  text-decoration: none;
  margin-top: 30px;
  height: 50px;
  padding: 0 30px;
  font-family: 'nunito', arial, sans-serif;
  font-weight: 400;
  font-size: 24px;
  border: 0;
  cursor: pointer;
}

.terminal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.terminal-content h4 {
  font-family: 'nunito', arial, sans-serif;
  font-size: 22px;
  font-weight: 800;
}

.terminal-content p {
  font-family: 'nunito', arial, sans-serif;
  color: rgba(0, 0, 0, 0.4);
}

.terminal-content .scriptsinstall {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.terminal-content .script-code {
  flex: 1;
  margin: 0 10px;
}

.terminal-content pre {
  margin: 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-family: 'nunito', arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  line-height: 1.5;
}

.layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.navigation {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  font-family: 'nunito', arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.navigation li {
  display: inline;
}

.navigation a {
  color: #000;
  text-decoration: none;
  padding: 8px 13px;
  display: inline-block;
  border-radius: 18px;
}

.navigation a:hover {
  background-color: #111;
  color: #fff;
}

.copyright {
  font-family: nunito,arial,sans-serif;
  font-size: .875rem;
}

.dotted {
  border-bottom: 1px dotted #ccc;
}

@media screen and (max-width: 750px) {
  footer .script-code {
    margin: 0;
  }
  footer pre {
    font-size: 12px;
  }
}
